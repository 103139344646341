<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <!-- <children /> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import Children from '@/views/app/apps/Children'
  export default {
    name: 'Parents',
    metaInfo() {
      return{
        // title will be injected into parent titleTemplate
        title: this.$t('general.Parents'),
      }
    },
    components: {
      Children: Children,
    }
  }
</script>
